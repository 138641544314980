<template>
  <v-row class="mt-4 ml-2">
    <v-col cols="12">
      <v-row>
        <v-col
          v-if="permisos.includes('events:create')"
          cols="12"
          md="2"
        >
          <v-btn
            color="primary"
            class="mb-4 me-3"
            @click="newItem"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>{{ $t('btn.insert') }}</span>
          </v-btn>
        </v-col>
        <v-col
          v-if="permisos.includes('events:create')"
          cols="12"
          md="2"
        >
          <v-btn
            color="primary"
            class="mb-4 me-3"
            @click="sociosList"
          >
            <span>{{ $t('landing.event.socios') }}</span>
          </v-btn>
        </v-col>

        <v-spacer></v-spacer>
        <v-col
          cols="12"
          md="3"
        >
          <v-select
            v-model="statu"
            :items="status"
            item-text="name"
            item-value="slug"
            :label="$t('lbl.state')"
            outlined
            dense
            hide-details
            @change="load()"
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="search"
            :append-icon="icons.mdiMagnify"
            :label="$t('btn.search')"
            single-line
            hide-details
            dense
            outlined
            @input="load()"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-divider class="my-1"></v-divider>
    </v-col>
    <template v-if="!loadingEvents">
      <v-col
        v-for="(event, indE) in events"
        :key="indE"
        cols="4"
      >
        <EventOne
          :event="event"
          :pos="indE"
          :countries="countries"
          @editItem="editItem"
        />
      </v-col>
    </template>

    <!--<SidebarDataEvent
      v-if="isSidebarDataEventActive"
      v-model="isSidebarDataEventActive"
      :evento="itemEdit"
      :catalogs="catalogs"
      :countries="countries"
      @getEvents="fetchDataFromEndpoints"
    />-->
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiInformation,
} from '@mdi/js'
import EventOne from './EventOne.vue'
import SidebarDataEvent from './SidebarDataEvent.vue'

export default {
  components: {
    EventOne,
    // eslint-disable-next-line vue/no-unused-components
    SidebarDataEvent,
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiInformation,
      },
      model: {},
      permisos: localStorage.getItem('permisos_auth'),
      loading: false,
      events: [],
      catalogs: [],
      countries: [],
      search: null,
      statu: 'activos',
      status: [],
      isSidebarDataEventActive: false,
      loadingEvents: false,
    }
  },
  computed: {
    ...mapState({
      emailsReserve: state => state.app.emailsReserve,
      ctasBancarias: state => state.app.ctasBancarias,
      dataBancaria: state => state.app.dataBancaria,
      posCotizador: state => state.app.posCotizador,
    }),
  },
  created() {
    this.status.push({
      name: this.$t('landing.event.activos'),
      slug: 'activos',
    })
    this.status.push({
      name: this.$t('landing.event.desactivos'),
      slug: 'desactivos',
    })
    this.status.push({
      name: this.$t('landing.event.cerrados'),
      slug: 'vencidos',
    })
    this.fetchDataFromEndpoints()
  },
  methods: {
    ...mapMutations([
      'addEmailAdminReserve',
      'updateEmailAdminReserve',
      'addCtasBancarias',
      'updateCtasBancarias',
      'setPosCotizador',
      'updateDataBancaria',
    ]),
    async fetchDataFromEndpoints() {
      const json = {
        statu: this.statu,
      }
      try {
        const [
          resCountries,
          resCatalogs,
          resEvents,
        ] = await Promise.all([
          this.axios
            .get('country_list?per_page=1000'),
          this.axios
            .post('catalogs/list-catalogos'),
          this.axios
            .post('events/index-events', json, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
        ])

        this.countries = resCountries.data.data
        this.catalogs = resCatalogs.data.data
        this.events = resEvents.data.data
      } catch (err) {
        console.error('Error fetching data:', err)
      }
    },
    async load() {
      this.loadingEvents = true
      const json = {
        search: this.search,
        statu: this.statu,
      }
      await this.axios
        .post('events/index-events', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.events = res.data.data
        })
        .finally(() => {
          this.loadingEvents = false
        })
    },
    newItem() {
      /* this.itemEdit = {
        title: null,
        slug: null,
        decription: null,
        dateFrom: '',
        dateTo: '',
        pabellon: null,
        stand: null,
        imgs: [],
        pdf_catalog: false,
        catalog_id: null,
        pdf: null,
        active: true,
        logo: null,
        size_pdf: 0,
      }
      this.isSidebarDataEventActive = true
      */
      localStorage.removeItem('event-id')
      this.$router.push({ name: 'events-update' })
    },
    editItem(id) {
      /* this.itemEdit = this.events.filter(e => e.id === id)[0]
      this.isSidebarDataEventActive = true */

      localStorage.setItem('event-id', id)
      this.$router.push({ name: 'events-update' })
    },
    sociosList() {
      this.$router.push({ name: 'events-socios' })
    },
  },
}
</script>
<style>
/**PARA OCULTAR LA FECHA DE HOY EN EL COMPONENTE DE HORA */
.vc-date-time .vc-date .vc-weekday,
.vc-date-time .vc-date .vc-month,
.vc-date-time .vc-date .vc-day,
.vc-date-time .vc-date .vc-year {
  display: none;
}
</style>
