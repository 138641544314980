<template>
  <v-card>
    <v-card-text>
      <v-img
        height="100px"
        contain
        :src="img"
      ></v-img>
    </v-card-text>
    <v-card-text class="d-flex mt-5">
      <v-avatar
        size="54"
        rounded
        color="primary"
        class="v-avatar-light-bg primary--text mt-1"
      >
        <div>
          <p class="font-weight-medium mb-0">
            {{ event.month }}
          </p>
          <p class="text-xl primary--text font-weight-bold mb-0">
            {{ event.day }}
          </p>
        </div>
      </v-avatar>

      <div class="ms-3">
        <p class="text-base font-weight-semibold text--primary mb-0">
          {{ getTitle(event) }}
        </p>
        <span class="text-xs">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
              >{{ getDescripcion(event) | truncate(80) }}</span>
            </template>
            <span>{{ getDescripcion(event) }}</span>
          </v-tooltip>
        </span>
      </div>
    </v-card-text>
    <v-card-text>
      <v-divider></v-divider>
    </v-card-text>
    <v-card-text class="text-center">
      <v-row>
        <v-col cols="4">
          <div>
            <v-icon
              size="30"
              color="primary"
            >
              mdi-star-outline
            </v-icon>
          </div>
          <span class="text-no-wrap primary--text">
            {{ $t('landing.intrested') }}
            <span v-if="event.cant_intrested > 0">
              <br />
              {{ event.cant_intrested }}
            </span>
          </span>
        </v-col>
        <v-col cols="4">
          <div>
            <v-icon
              size="30"
              color="primary"
            >
              mdi-account-outline
            </v-icon>
          </div>
          <span class="text-no-wrap primary--text">
            {{ $t('landing.solicitude') }}
            <span v-if="event.cant_invited > 0">
              <br />
              {{ event.cant_invited }}
            </span>
          </span>
        </v-col>
        <v-col
          cols="4"
        >
          <div>
            <v-icon
              size="30"
              color="primary"
            >
              mdi-check-circle-outline
            </v-icon>
          </div>
          <span class="text-no-wrap primary--text">
            {{ $t('landing.confirm') }}
            <span v-if="event.cant_confirm > 0">
              <br />
              {{ event.cant_confirm }}
            </span>
          </span>
        </v-col>
        <!--<v-col cols="3">
          <div>
            <v-icon
              size="30"
            >
              mdi-dots-horizontal
            </v-icon>
          </div>
          <span class="text-no-wrap text--primary">
            {{ $t('landing.more') }}
          </span>
        </v-col>-->
      </v-row>
    </v-card-text>
    <v-card-text>
      <v-divider></v-divider>
    </v-card-text>
    <v-card-text class="text-center">
      <v-row>
        <v-col cols="8">
          <v-btn
            color="primary"
            block
            outlined
            @click="$emit('editItem', event.id)"
          >
            <span>{{ $t('btn.edit') }}</span>
          </v-btn>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="4">
          <v-switch
            v-if="!event.vencido"
            v-model="event.active"
            hide-details
            class="mt-1"
            :label="$t('lbl.active')"
            :disabled="event.vencido"
            @click="changePublico"
          ></v-switch>
          <v-tooltip
            v-if="event.vencido"
            top
            color="primary"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="error">
                  mdi-timer-off-outline
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('landing.event.cerrado') }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text>
      <v-divider></v-divider>
    </v-card-text>
    <v-card-text>
      <div
        class="d-flex align-center"
      >
        <v-icon
          size="20"
        >
          {{ icons.mdiClockOutline }}
        </v-icon>
        <div class="ms-2">
          <p class="text--primary mb-0">
            {{ $moment(event.dateFrom).locale($i18n.locale).format('dddd, D MMMM') }}
          </p>
          <!--<span class="text-xs">{{ data.subtitle }}</span>-->
        </div>
      </div>

      <div
        v-if="event.country"
        class="d-flex align-center mt-2"
      >
        <v-icon
          size="20"
        >
          {{ icons.mdiMapMarkerOutline }}
        </v-icon>
        <div class="ms-2">
          <p class="text--primary mb-0">
            {{ `${event.city}, ${event.country}` }}
          </p>
          <!--<span class="text-xs">{{ data.subtitle }}</span>-->
        </div>
      </div>
      <div
        v-if="event.pabellon || event.stand"
        class="d-flex align-center mt-2"
      >
        <v-icon
          size="20"
        >
          mdi-location-enter
        </v-icon>
        <div class="ms-2">
          <p class="text--primary mb-0">
            <template v-if="event.pabellon">
              {{ $t('landing.eventoPabellon') }} {{ event.pabellon }}
            </template>
            <span v-if="event.pabellon && event.stand">-</span>
            <template v-if="event.stand">
              {{ $t('landing.eventoStand') }} {{ event.stand }}
            </template>
          </p>
        </div>
      </div>
    </v-card-text>
    <v-card-text>
      <v-divider></v-divider>
    </v-card-text>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <v-btn
            color="primary"
            outlined
            block
            @click="goCita(event)"
          >
            {{ $t('landing.eventSoli.horarios') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>

    <v-dialog
      v-model="showDialogPublico"
      scrollable
      max-width="400px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ $t('landing.evento') }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-card-text>
            {{ event.active ? $t('landing.event.activar') : $t('landing.event.desactivar') }}
          </v-card-text>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="canceleChangePublico()"
          >
            {{ $t('btn.cancele') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="showEditActive(event)"
          >
            {{ $t('btn.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
  mdiClockOutline,
  mdiMapMarkerOutline,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    event: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    countries: {
      type: Array,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      urlS3: process.env.VUE_APP_API_URL_S3_HOTEL,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
        mdiClockOutline,
        mdiMapMarkerOutline,
      },
      loading: false,
      permisos: localStorage.getItem('permisos_auth'),
      developerMeetup: [
        { icon: mdiClockOutline, title: 'Tuesday, 24 january, 10:20 - 12:30', subtitle: 'After 1 week' },
        { icon: mdiMapMarkerOutline, title: 'The Rochard NYC', subtitle: '1305 Lexington Ave, New York' },
      ],
      imgs: [],
      img: null,
      showDialogPublico: false,
    }
  },
  computed: {
    ...mapState({
      emailsReserve: state => state.app.emailsReserve,
    }),
  },
  created() {
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/events/event1.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/events/event2.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/events/event3.svg'))
    // eslint-disable-next-line import/no-unresolved
    this.imgs.push(require('@/assets/img/events/event4.svg'))
    this.shuffleDeck()
  },
  methods: {
    ...mapMutations(['deleteEmailAdminReserve']),
    getTitle(item) {
      if (this.$i18n.locale === 'es') {
        return item.title
      }

      return item[`title_${this.$i18n.locale}`]
    },
    getDescripcion(item) {
      if (this.$i18n.locale === 'es') {
        return item.description
      }

      return item[`description_${this.$i18n.locale}`]
    },
    async showEditActive(item) {
      await this.axios
        .post('events/update-active-event', item, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === true) {
            this.$toast.success(this.$t('msg.infoSuccess'))
          }
        })
        .finally(() => {
          this.showDialogPublico = false
        })
    },
    shuffleDeck() {
      const numbers = [...this.imgs]
      let first
      let second
      let temp
      const count = numbers.length
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 10; i++) {
        first = Math.floor(Math.random() * count)
        second = Math.floor(Math.random() * count)
        temp = numbers[first]
        numbers[first] = numbers[second]
        numbers[second] = temp
      }
      this.imgs = numbers
      if (this.event.img) {
        this.img = this.urlS3 + this.event.img
      } else {
        // eslint-disable-next-line prefer-destructuring
        this.img = this.imgs[0]
      }
    },
    changePublico() {
      this.showDialogPublico = true
    },
    canceleChangePublico() {
      this.showDialogPublico = !this.showDialogPublico
      this.event.active = !this.event.active
    },
    goCita(iten) {
      localStorage.setItem('event-id', iten.id)
      this.$router.push({ name: 'events-citas', params: { slug: iten.slug } })
    },
  },
}
</script>
<style scoped>
::v-deep .my-input .v-label,
::v-deep .my-input input {
  font-size: 12px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
::v-deep .v-text-field__details {
  padding: 0 0 0 0;
}
</style>
