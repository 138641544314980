<template>
  <v-navigation-drawer
    :value="isSidebarDataEventActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    width="550"
    app
    @input="val => $emit('update:is-sidebar-data-event-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center">
        <h4 class="font-weight-semibold text-base">
          {{ $t('landing.evento') }}
        </h4>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="$emit('update:is-sidebar-data-event-active', false)"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text class="pt-5">
        <v-form ref="stepForm">
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="evento.title"
                :label="`${$t('lbl.title')}`"
                outlined
                dense
                hide-details="auto"
                :rules="textRequiered"
                @input="generateSlug"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <v-text-field
                v-if="!writing"
                v-model="evento.slug"
                label="Slug/URL"
                outlined
                dense
                hide-details="auto"
                :rules="textRequiered"
                :disabled="evento.id ? null : false"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <v-textarea
                v-model="evento.description"
                :label="`${$t('lbl.description')}`"
                outlined
                dense
                rows="2"
                hide-details="auto"
                :rules="textRequiered"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="6"
            >
              <v-menu
                v-model="menuFrom"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="evento.dateFrom"
                    :label="$t('landing.eventoFrom')"
                    persistent-hint
                    readonly
                    v-bind="attrs"
                    outlined
                    dense
                    style="border-radius: 5px 0px 0px 5px"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="evento.dateFrom"
                  no-title
                  locale="es"
                  :show-current="false"
                  @input="
                    menuFrom = false
                    activeToDate()
                  "
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col
              cols="6"
            >
              <v-menu
                v-model="menuTo"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="evento.dateTo"
                    :disabled="!evento.dateFrom"
                    :label="$t('landing.eventoTo')"
                    persistent-hint
                    readonly
                    v-bind="attrs"
                    outlined
                    dense
                    style="border-radius: 0px 5px 5px 0px"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="evento.dateTo"
                  no-title
                  locale="es"
                  :show-current="false"
                  :min="
                    evento.dateFrom
                      ? $moment(evento.dateFrom)
                        .add(1, 'day')
                        .toDate()
                        .toISOString()
                        .substr(0, 10)
                      : ''
                  "
                  @input="menuTo = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="evento.pabellon"
                :label="`${$t('landing.eventoPabellon')}`"
                outlined
                dense
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="evento.stand"
                :label="`${$t('landing.eventoStand')}`"
                outlined
                dense
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-select
                v-model="evento.country"
                :items="countries"
                :label="$t('register.country')"
                item-text="name"
                item-value="name"
                outlined
                dense
                hide-details="auto"
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="evento.city"
                :label="`${$t('register.locality')}`"
                outlined
                dense
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="9"
            >
              <v-file-input
                v-if="!pdf_catalog"
                v-model="pdf"
                accept=".pdf"
                show-size
                outlined
                dense
                :label="$t('landing.catalog')"
                hide-details
                @change="setCatalog"
              ></v-file-input>
              <v-select
                v-else
                v-model="evento.catalog_id"
                :items="catalogs"
                :label="$t('landing.catalog')"
                outlined
                dense
                item-text="title"
                item-value="id"
                hide-details="auto"
              ></v-select>
            </v-col>
            <v-col cols="3">
              <v-switch
                v-model="pdf_catalog"
                hide-details
                class="mt-1"
                :label="$t('landing.eventoPdfExtern')"
                @click:event="setChangePdf"
              ></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-file-input
                v-model="logo"
                accept="image/*"
                show-size
                outlined
                dense
                label="Logo"
                hide-details
                @change="setLogo"
              ></v-file-input>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-file-input
                v-model="imgs"
                accept=".jpg, .png, .webp"
                show-size
                outlined
                dense
                :label="$t('landing.eventoFotos')"
                hide-details
                multiple
                @change="setImgs"
              ></v-file-input>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-btn
                color="error"
                block
                @click="$emit('update:is-sidebar-data-event-active', false)"
              >
                {{ $t('btn.cancele') }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-btn
                color="primary"
                outlined
                block
                @click="save"
              >
                {{ $t('btn.send') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <Loading
      v-if="isLoading"
      :full-page="true"
      :color="$vuetify.theme.themes.light.primary"
    />
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { mdiClose } from '@mdi/js'
// eslint-disable-next-line no-unused-vars, import/no-unresolved
import Loading from '@/views/utils/loading/bars.vue'

export default {
  components: {
    Loading,
  },
  model: {
    prop: 'isSidebarDataEventActive',
    event: 'update:is-sidebar-data-event-active',
  },
  props: {
    isSidebarDataEventActive: {
      type: Boolean,
      required: true,
    },
    // eslint-disable-next-line vue/require-default-prop
    evento: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    catalogs: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    countries: {
      type: Array,
    },
  },
  data() {
    return {
      rutaAPI: process.env.VUE_APP_API_URL,
      stepForm: null,
      icons: {
        mdiClose,
      },
      writing: false,
      textRequiered: [v => !!v || 'Campo requerido'],
      emailRules: [v => !!v || 'Campo requerido', v => /.+@.+/.test(v) || 'E-mail debe ser válido'],
      phoneRules: [v => Number.isInteger(Number(v)) || 'Debe ser solo números'],
      loading: false,
      imgs: [],
      logo: null,
      pdf: null,
      menuFrom: false,
      menuTo: false,
      isLoading: false,
      pdf_catalog: false,
    }
  },
  computed: {
    ...mapState({
      clientCarShop: state => state.app.clientCarShop,
      loadingBtn: state => state.app.loadingBtn,
      carItems: state => state.app.carItems,
      proveedorApis: state => state.app.proveedorApis,
      clientsApis: state => state.app.clientsApis,
    }),
    computedFromDateFormatted() {
      return this.evento.dateFrom ? this.$moment(this.evento.dateFrom).format('D-MMM-YY') : ''
    },
    computedToDateFormatted() {
      return this.evento.dateTo ? this.$moment(this.evento.dateTo).format('D-MMM-YY') : ''
    },
  },
  created() {
    this.pdf_catalog = this.evento.pdf_catalog
  },
  methods: {
    ...mapMutations(['updateProveedorApi', 'addProveedorApi', 'updateClientApi', 'addClientApi']),
    setChangePdf() {
      this.pdf_catalog = !this.pdf_catalog
      this.evento.pdf_catalog = this.pdf_catalog
    },
    generateSlug() {
      this.writing = true

      // Convertir a minúsculas
      let text = this.evento.title.toLowerCase()

      // Reemplazar espacios y caracteres especiales
      text = text.replace(/[\s]+/g, '') // Reemplaza espacios por guiones
      // eslint-disable-next-line no-useless-escape
      text = text.replace(/[^\w\-]+/g, '') // Elimina caracteres no válidos
      text = text.replace(/-+/g, '') // Reemplaza múltiples guiones por uno solo
      text = text.replace(/^-+|-+$/g, '') // Elimina guiones al principio y al final

      this.writing = false
      this.evento.slug = text
    },
    setImgs() {
      if (this.imgs.length > 0) {
        this.isLoading = true
        const formData = new FormData()
        this.imgs.forEach(element => {
          formData.append('images[]', element)
        })

        this.axios
          .post('events/upload-imgs-event', formData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            if (res.data.success === true) {
              // this.evento.imgs = res.data.data.dir
              if (res.data.data.imgsNotSize) {
                if (res.data.data.imgsNotSize.length > 0) {
                  res.data.data.imgsNotSize.forEach(element => {
                    this.$toast.error(element)
                  })
                  this.$toast.error(this.$t('msg.msgImgsNotSize', { n: '1080x720' }))
                }
              }
              this.evento.imgs = res.data.data.data
            }
          })
          .catch(error => console.log(error))
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    activeToDate() {
      setTimeout(() => {
        this.menuTo = true
        this.evento.dateTo = this.$moment(this.evento.dateFrom)
          .add('days', 1)
          .format('YYYY-MM-DD')
      }, 100)
    },
    setLogo() {
      if (this.logo) {
        this.isLoading = true
        const formData = new FormData()

        formData.append('logo', this.logo)

        this.axios
          .post('events/upload-logo-event', formData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            if (res.data.success === true) {
              this.evento.logo = res.data.data.data
            }
          })
          .catch(error => console.log(error))
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    setCatalog() {
      if (this.pdf) {
        this.isLoading = true
        const formData = new FormData()

        formData.append('pdf', this.pdf)

        this.axios
          .post('events/upload-pdf-event', formData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            if (res.data.success === true) {
              this.evento.pdf = res.data.data.data
              this.evento.size_pdf = res.data.data.size
            }
          })
          .catch(error => console.log(error))
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    save() {
      if (this.evento.title
        && this.evento.slug
        && this.evento.description
        && this.evento.dateFrom
        && this.evento.dateTo) {
        this.axios
          .post('events/update-event', this.evento, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            if (res.data.success === true) {
              this.$toast.success(this.$t('msg.infoSuccess'))
              this.$emit('update:is-sidebar-data-event-active', false)
              this.$emit('getEvents')
            } else {
              this.$toast.error(res.data.message)
            }
          })
          .catch(error => console.log(error))
          .finally(() => {
            this.isLoading = false
          })
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
  },
}
</script>
